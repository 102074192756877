import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Footer from '../../components/Footer';
// import Background from '../../assets/images/meet1-compressed.jpg';
import Paper from '@material-ui/core/Paper';

const styles = {
  root: {
    width: 'auto',
    padding: 8 * 3,
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'flex-start',
    position: 'relative',
    zIndex: '2',
  },
  paragraph: {
    marginBottom: '0.9em',
    padding: '0px',
    fontSize: '1.2em',
  },
  background: {
    // backgroundImage: `url(${Background})`,
    backgroundImage: `url(./backgrounds/board.jpg)`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    position: 'fixed',
    zIndex: '1',
    left: 0,
    top: 0,
    margin: '0 0 -24px',
    width: '100%',
    height: '100vh',
  },
  paper: {
    width: 'auto',
    margin: '20px',
    padding: '12px 25px 0',
    backgroundColor: 'rgba(255, 247, 228, 0.85)',
  },
};

function BoardPage(props) {
  const { classes } = props;

  return (
    <Fragment>
      <div className={classes.background}></div>
      <div className={classes.root}>  
        <Paper className={classes.paper} elevation={1}>
          <Typography component="h2" variant="h5" color="inherit" gutterBottom>
            PGR Rambler Club Board Members
          </Typography>
          <Typography variant="body1" className={classes.paragraph}>
            FOUNDER &amp; PRESIDENT: Stan Kelly - Porterville, CA 408-821-7812
          </Typography>
          <Typography variant="body1" className={classes.paragraph}>
            TREASURER: Randy Sikk - {/* 428 Red Robin Drive */} Patterson, CA{/* 95363 */} 209-499-7703
          </Typography>
          <Typography variant="body1" className={classes.paragraph}>
            SECRETARY: Neil Rodrigues - 408-374-6288
          </Typography>
          <Typography variant="body1" className={classes.paragraph}>
            NEWSLETTER: Open - volunteers welcome!
          </Typography>
          <Typography variant="body1" className={classes.paragraph}>
            WEBSITE: Tom Carter - San Jose, CA
          </Typography>
        </Paper>
        <Footer />
      </div>
    </Fragment>
  );
}

BoardPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BoardPage);
