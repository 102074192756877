import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Footer from '../../components/Footer';
// import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";

const styles = {
  root: {
    width: 'auto',
    padding: 8 * 3,
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'flex-start',
    position: 'relative',
    zIndex: '2',
  },
  title: {
    marginBottom: 0,
    padding: '20px 20px 0 20px',
  },
  pgrrimage: {
    margin: '0 15px',
    display: 'block',
    width: '145px',
    height: '145px',
    borderRadius: '4px',
  },
  amcrcimage: {
    margin: 'auto',
    display: 'block',
    width: '144px',
    height: '145px',
    borderRadius: '4px',
  },
  paragraph: {
    padding: '20px',
    fontSize: '1.1em',
  },
  last: {
    padding: '0 20px 20px 20px',
    fontSize: '1.1em',
  },
  paper: {
    width: '45%',
    margin: '20px',
    backgroundColor: 'rgba(255, 247, 228, 0.85)',
  },
  paperButtons: {
    width: '30%',
    margin: '20px',
    backgroundColor: 'transparent',
  },
  paymentBlock: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    margin: '20px 30px 20px auto',
  },
  background: {
    backgroundImage: `url(./backgrounds/join.jpg)`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    position: 'fixed',
    zIndex: '1',
    left: 0,
    top: 0,
    margin: '0 0 -24px',
    width: '100%',
    height: '100vh',
  },
  textField: {
    marginLeft: '20px',
    marginRight: '20px',
    marginBottom: '12px',
    width: 'calc(100% - 40px)'
  },
  textFieldTop: {
    marginTop: '30px',
    marginLeft: '20px',
    marginRight: '20px',
    marginBottom: '12px',
    width: 'calc(100% - 40px)'
  },
  '@media (max-width: 1065px)': {
    homeimage: {
      margin: '20px 0 20px auto',
      flexDirection: 'column',
    },
    pgrrimage: {
      margin: '0 0 15px',
    },
    paper: {
      width: '50%',
      margin: '20px',
    },
  },
  '@media (max-width: 860px)': {
    homeimage: {
      margin: '20px 0px',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    pgrrimage: {
      margin: '0 15px',
      width: '144px',
      height: '144px'
    },
    amcrcimage: {
      margin: '0 30px 0 auto',
    },
    paper: {
      width: '95%',
      margin: '10px',
    },
    root: {
      flexDirection: 'column',
    },
  },
};

const JoinPage = props => {
  const { classes } = props;

  const isValidAMCRC = async ({ AMCRCnumber }) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Access-Control-Allow-Headers", "*");
    myHeaders.append("Access-Control-Allow-Methods", "POST, OPTIONS");
    myHeaders.append("Accept", "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9");
    myHeaders.append("Origin", "http://localhost:3000");
    myHeaders.append("Referer", "http://localhost:3000/join");
    let urlencoded = new URLSearchParams();
    urlencoded.append("searchtype", "MembershipID");
    urlencoded.append("searchterm", AMCRCnumber);
    let requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
    const response = await fetch("https://amcrc.com/AMCRC_Membership/lookup_member.php", requestOptions);
    if (!response.ok) {
      throw new Error('Data coud not be fetched!');
    } else {
      return response;
    }
  }
  useEffect(() => {
    isValidAMCRC('7399')
      .then(response => response.text())
      .then(result => console.log("result", result))
      .catch(error => console.log('error', error));
  }, []);

  const clientid = "AaO8i9TNKUq6Ew_IhzvqvJYOXPhSc8zsPPjlXFk_Bdt77uHs_MdXmnpTKBqUx4T3x4IaaBnBZw5nd-FJ";
  const currency = "USD";
  const style = {
    "layout":"vertical",
    "shape": "pill",
    "height": 40,
  };
  let amount = "25";

  const handleChange = (event) => {
    // Currently any change will reduce the price from $25 to $15
    // TODO: Need isValidAMCRC() function to validate they are an AMCRC member before allowing price change
    // TODO: Need to Regex the input field to max 8 numbers
    event.target.value.replace(/\D/,'')
    amount = "15";
  }

  // const ButtonWrapper = ({ currency, showSpinner }) => {
    // const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

  //   useEffect(() => {
  //       dispatch({
  //           type: "resetOptions",
  //           value: {
  //               ...options,
  //               currency: currency,
  //           },
  //       });
  //   }, []); // currency, showSpinner

  //   return (
  //     <Fragment>
  //       { (showSpinner && isPending) && <div className="spinner" /> }
  //       <PayPalButtons
  //         style={style}
  //         disabled={false}
  //         forceReRender={[amount, currency, style]}
  //         fundingSource={undefined}
  //         createOrder={(data, actions) => {
  //           return actions.order
  //             .create({
  //                 purchase_units: [
  //                     {
  //                         amount: {
  //                             currency_code: currency,
  //                             value: amount,
  //                         },
  //                     },
  //                 ],
  //             })
  //             .then((orderId) => {
  //                 // Your code here after create the order
  //                 return orderId;
  //             });
  //           }}
  //         onApprove={function (data, actions) {
  //           return actions.order.capture().then((details) => {
  //             const name = details.payer.name.given_name;
  //             alert(`Transaction completed by ${name}`);
  //             // Your code here after capture the order
  //         });
  //         }}
  //       />
  //     </Fragment>
  //   );
  // }

  return (
    <Fragment>
      <div className={classes.background}></div>
      <div className={classes.root}>
        <Paper className={classes.paper} elevation={1}>
          <Typography component="h2" variant="h5" color="inherit" className={classes.title}>
            Join or Renew Membership:
          </Typography>
          <Typography variant="body1" className={classes.paragraph}>
            Dues are $30.00 per year payable each January which includes membership in PGRR and the national AMCRC club.  ($25.00 to AMCRC and $5.00 to PGRR. PGRR will make the payment to AMCRC for the member).
          </Typography>

        </Paper>
        <Paper className={classes.paper} elevation={1}>
          {/* <TextField className={classes.textFieldTop} label="First Name" variant="outlined" margin="dense" />
          <TextField className={classes.textField} label="Last Name" variant="outlined" margin="dense" />
          <TextField className={classes.textField} label="Email" variant="outlined" margin="dense" />
          <TextField className={classes.textField} label="AMCRC Member Number" variant="outlined" type="tel" margin="dense" inputProps={{ pattern: '[0-9]' }} onChange={handleChange} />
          <Typography variant="body1" className={classes.paragraph}>
            We will use your Paypal mailing address.
          </Typography> */}
          <Typography variant="body1" className={classes.last}></Typography>
          <Typography variant="body1" className={classes.last}>
            Under development and coming soon, join or renew using Paypal!
            <br />In the mean time, please print and fill out this <a href="PGRR_MEMBERSHIP_FORM.pdf" target="_blank">Membership form</a>.
          </Typography>

        </Paper>
        {/* <Paper className={classes.paperButtons} elevation={1}>
          <PayPalScriptProvider options={{ "client-id": clientid, components: "buttons", currency: currency }}>
            <ButtonWrapper currency={currency} showSpinner={false} className={classes.buttons} />
          </PayPalScriptProvider>
          <Typography variant="body1" className={classes.last}></Typography>
          <Typography variant="body1" className={classes.last}>
            Membership in the parent organization, <a href="https://amcrc.com/join/join.htm" target="_blank" rel="noopener noreferrer">AMC Rambler Club (AMCRC)</a> is encouraged.
          </Typography>
        </Paper> */}
        <Footer />
      </div>
    </Fragment>
  );
}

JoinPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(JoinPage);
