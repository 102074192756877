import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    width: '100%',
    textAlign: 'center',
    position: 'fixed',
    left: 0,
    bottom: 0,
  },
  text: {
    color: 'white',
  },
  '@media (max-width: 860px)': {
    root: {
      position: 'relative',
      marginTop: 'auto',
    },
  },
});

function Footer(props) {
  const { classes } = props;

  return (
    <div className={classes.root}>  
      <Typography className={classes.text}>
        &#169; Copyright {(new Date().getFullYear())} Pacific Gold Rush Ramblers.  All rights reserved.
      </Typography>
    </div>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);
