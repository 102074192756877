import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import pgrrLogo from '../../assets/images/pgrr_logo.jpg';
import amcrcLogo from '../../assets/images/amcrc_logo.jpg';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Footer from '../../components/Footer';
import Button from '@material-ui/core/Button';

const styles = {
  root: {
    width: 'auto',
    padding: 8 * 3,
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'flex-start',
    position: 'relative',
    zIndex: '2',
  },
  homelinks: {
    width: '100%',
  },
  pgrrimage: {
    margin: '0 15px',
    display: 'block',
    width: '145px',
    height: '145px',
    borderRadius: '4px',
  },
  amcrcimage: {
    margin: 'auto',
    display: 'block',
    width: '144px',
    height: '145px',
    borderRadius: '4px',
  },
  forsaleimage: {
    margin: 'auto',
    display: 'block',
    width: '304px',
    height: '168px',
    borderRadius: '4px',
  },
  openButton: {
    margin: '10px;',
    display: 'block',
    width: '200px',
    height: '44px',
    textAlign: 'center',
    verticalAlign: 'middle',
    lineHeight: '34px',
  },
  paragraph: {
    padding: '20px',
    fontSize: '1.2em',
  },
  paper: {
    width: '30%',
    margin: '20px',
    backgroundColor: 'rgba(255, 247, 228, 0.85)',
  },
  homelogos: {
    display: 'flex',
    flexDirection: 'column',
  },
  homeimage: {
    display: 'flex',
    flexDirection: 'row',
    margin: '20px 30px 20px auto',
  },
  homebuttons: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
  },
  background: {
    backgroundImage: `url(./backgrounds/home.jpg)`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    position: 'fixed',
    zIndex: '1',
    left: 0,
    top: 0,
    margin: '0 0 -24px',
    width: '100%',
    height: '100vh',
  },
  '@media (max-width: 1065px)': {
    homeimage: {
      margin: '20px 0 20px auto',
      flexDirection: 'column',
    },
    homebutton: {
      margin: '20px 0 20px auto',
    },
    pgrrimage: {
      margin: '0 0 15px',
    },
    paper: {
      width: '50%',
      margin: '20px',
    },
  },
  '@media (max-width: 860px)': {
    homeimage: {
      margin: '20px 0px',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    homebuttons: {
      margin: '20px 0px',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    pgrrimage: {
      margin: '0 15px',
      width: '144px',
      height: '144px'
    },
    amcrcimage: {
      margin: '0 30px 0 auto',
    },
    paper: {
      width: '95%',
      margin: '10px',
    },
    root: {
      flexDirection: 'column',
    },
  },
};

function HomePage(props) {
  const { classes } = props;
  return (
    <Fragment>
      <div className={classes.background}></div>
      <div className={classes.root}>
        <Paper className={classes.paper} elevation={1}>
          <Typography variant="body1" className={classes.paragraph}>
            The Pacific Gold Rush Ramblers is the Northern California chapter of the <a href="http://www.amcrc.com/">AMC Rambler Club (AMCRC)</a>.
            Our main purpose is to enjoy the use, restoration and preservation of the Rambler related automobiles built by Nash, Hudson and 
            Amercian Motors Corporation, of all years up to 1987.  This selection includes the JEEP and other brands falling under these monikers.
          </Typography>
        </Paper>
        <Paper className={classes.paper} elevation={1}>
          <Typography variant="body1" className={classes.paragraph}>
            Although our focus is toward these listed vehicles, our club is open to anyone and their classic machines with an interest in the 
            automobiles built within the Rambler ancestry.  This club holds meets, shows, drive-tours, has parts connections, good people and fun!  
            Claimed the most active chapter in the whole AMCRC!  Please come and join in on the fun!
          </Typography>
        </Paper>
        <div className={classes.homelogos}>
          <div className={classes.homeimage}>
            <img src={pgrrLogo} alt="PGRR" className={classes.pgrrimage} />
            <a href="http://www.amcrc.com/"><img src={amcrcLogo} alt="AMCRC" className={classes.amcrcimage} /></a>
          </div>
          <div className={classes.homebuttons}>
            <Button className={classes.openButton} variant="contained" color="secondary" component={Link} to="/classifieds">
              Member Classifieds
            </Button>
            {/* <Button className={classes.openButton} variant="contained" color="secondary" component={Link} to="/santamaria">
              Santa Maria 2023!
            </Button> */}
          </div>
        </div>
        <Footer />
      </div>
    </Fragment>
  );
}

HomePage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HomePage);
