import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Iframe from 'react-iframe';
import Button from '@material-ui/core/Button';

const styles = {
  root: {
    width: 'auto',
    height: 'calc(100vh - 76px)',
  },
  openButton: {
    position: 'absolute',
    right: '50px',
    top: '135px',
    zIndex: '2',
    width: '200px',
    height: '44px',
  }
};

function SantamariaPage(props) {
  const { classes } = props;

  const fetchHeader = (filename, property) => {
    try {
        let req=new XMLHttpRequest();
        req.open("HEAD", filename, false);
        req.send(null);
        if(req.status === 200){
            return req.getResponseHeader(property);
        }
        else return false;
    } catch(er) {
        return er.message;
    }
  }
  const nextEvent = "../../SANTAMARIA.pdf"
  const modifiedDate = fetchHeader(nextEvent,'Last-Modified');
  const unixTime = Date.parse(modifiedDate);
  const nextEventUrl = isNaN(unixTime) ? nextEvent : nextEvent + '?cache=' + unixTime ;

  return (
    <div className={classes.root}>
      <Button className={classes.openButton} variant="contained" color="secondary" component={RouterLink} to="/events">
        Club Calendar
      </Button>
      <Iframe url={nextEventUrl}
        width="100%"
        height="100%"
        id="PDFviewer"
        display="initial"
        position="relative"
      />
    </div>
  );
}

SantamariaPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SantamariaPage);
