import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ramblerclubIcon from '../../assets/images/amcrc_logo.png';
// import facebookIcon from '../../assets/images/facebook.png';
// import yahooIcon from '../../assets/images/yahoo.png';
import Paper from '@material-ui/core/Paper';
import Footer from '../../components/Footer';
// import Background from '../../assets/images/tour2-compressed.jpg';

const styles = {
  root: {
    width: 'auto',
    padding: 8 * 3,
    justifyContent: 'space-evenly',
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    zIndex: '2',
    alignItems: 'flex-start',
  },
  paragraph: {
    marginBottom: '0.9em',
    fontSize: '1.2em',
  },
  link: {
    padding: 0,
  },
  listitem: {
    paddingTop: '3px',
    paddingBottom: '3px',
  },
  amclogo: {
    width: '60px',
    height: '60px',
  },
  logo: {
    width: '32.5px',
    height: '32.5px',
  },
  childLeft: {
    width: '410px',
    margin: '20px',
    backgroundColor: 'rgba(255, 247, 228, 0.75)',
  },
  childRight: {
    width: '430px',
    margin: '20px',
    backgroundColor: 'rgba(255, 247, 228, 0.75)',
  },
  background: {
    // backgroundImage: `url(${Background})`,
    backgroundImage: `url(./backgrounds/links.jpg)`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    position: 'fixed',
    zIndex: '1',
    left: 0,
    top: 0,
    margin: '0 0 -24px',
    width: '100%',
    height: '100vh',
    overflow: 'hidden',
  },
  backgroundImage: {
    width: '100%',
  },
  headings: {
    marginLeft: '10px',
  },
  '@media (max-width: 886px)': {
    root: {
      flexDirection: 'column',
    },
  },
};

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

function LinksPage(props) {
  const { classes } = props;

  return (
    <Fragment>
      <div className={classes.background}>
        {/* <img className={classes.backgroundImage} src='/backgrounds/links.jpg' alt='0' /> */}
      </div>
      <div className={classes.root}>
        <Paper className={classes.childLeft} elevation={1}>
          <Typography component="h2" variant="h5" color="inherit" className={classes.headings} gutterBottom>
            PGRR and AMC related pages
          </Typography>
        
          <List>
            <ListItem className={classes.listitem}>
              <ListItemLink href="https://groups.io/g/PGRRamblers" className={classes.link}>
                <ListItemText primary="Our Email List - PGRRamblers on Groups.io" />
                {/*
                <ListItemIcon>
                  <img src={yahooIcon} alt="Yahoo" />
                </ListItemIcon>
                */}
              </ListItemLink>
            </ListItem>
            <ListItem className={classes.listitem}>
              <ListItemLink href="https://www.facebook.com/groups/13063618841/" className={classes.link}>
                <ListItemText primary="Our Facebook Group - Pacific Gold Rush Ramblers" />
                {/*
                <ListItemIcon>
                  <img src={facebookIcon} alt="Facebook" className={classes.logo} />
                </ListItemIcon>
                */}
              </ListItemLink>
            </ListItem>
            <ListItem className={classes.listitem}>
              <ListItemLink href="https://www.dropbox.com/sh/56tu8nc0ug1hjhy/AAC8fNDethN88n2LO7xopkCca?dl=0" className={classes.link}>
                <ListItemText primary="PGRR club event photos" />
              </ListItemLink>
            </ListItem>
            <ListItem className={classes.listitem}>
              <ListItemLink href="http://www.amcrc.com/" className={classes.link}>
                <ListItemText primary="AMC Rambler Club (AMCRC) - National club" />
                {/*
                <ListItemIcon>
                  <img src={ramblerclubIcon} alt="International Rambler Club" className={classes.amclogo} />
                </ListItemIcon>
                */}
              </ListItemLink>
            </ListItem>
            <ListItem className={classes.listitem}>
              <ListItemLink href="http://westcoastamc.com/" className={classes.link}>
                <ListItemText primary="West Coast AMC" />
              </ListItemLink>
            </ListItem>
            <ListItem className={classes.listitem}>
              <ListItemLink href="http://www.planethoustonamx.com/" className={classes.link}>
                <ListItemText primary="Planet Houston AMX" />
              </ListItemLink>
            </ListItem>
            <ListItem className={classes.listitem}>
              <ListItemLink href="http://hdamc.amcrc.com/" className={classes.link}>
                <ListItemText primary="High Desert AMC Car Club of Northern Nevada" />
              </ListItemLink>
            </ListItem>
            <ListItem className={classes.listitem}>
              <ListItemLink href="https://snamc.amcrc.com/" className={classes.link}>
                <ListItemText primary="Southern Nevada AMC Club" />
              </ListItemLink>
            </ListItem>
            <ListItem className={classes.listitem}>
              <ListItemLink href="https://amo.club/" className={classes.link}>
                <ListItemText primary="AMO - American Motors Owners Association" />
              </ListItemLink>
            </ListItem>
            <ListItem className={classes.listitem}>
              <ListItemLink href="https://www.facebook.com/SoCalRamblers/" className={classes.link}>
                <ListItemText primary="So Cal Rambler Club" />
              </ListItemLink>
            </ListItem>
            <ListItem className={classes.listitem}>
              <ListItemLink href="/amc" className={classes.link}>
                <ListItemText primary="1967 AMC Mariner, Westerner and Briarcliff" />
              </ListItemLink>
            </ListItem>
          </List>
        </Paper>
        <Paper className={classes.childRight} elevation={1}>
          <Typography component="h3" variant="h6" color="inherit" className={classes.headings} gutterBottom>
            Parts sources, AMC history, etc.
          </Typography>
          <List>
            <ListItem className={classes.listitem}>
              <ListItemLink href="http://www.ramblerparts.com" className={classes.link}>
                <ListItemText primary="AMC ACRES (formerly Galvin's Rambler Parts)" />
              </ListItemLink>
            </ListItem>
            <ListItem className={classes.listitem}>
              <ListItemLink href="http://www.americanpartsdepot.com" className={classes.link}>
                <ListItemText primary="American Parts Depot" />
              </ListItemLink>
            </ListItem>
            <ListItem className={classes.listitem}>
              <ListItemLink href="http://www.kennedyamerican.com" className={classes.link}>
                <ListItemText primary="Kennedy American" />
              </ListItemLink>
            </ListItem>
            <ListItem className={classes.listitem}>
              <ListItemLink href="http://www.blaserauto.com" className={classes.link}>
                <ListItemText primary="Blasers - N.O.S. Parts" />
              </ListItemLink>
            </ListItem>
            <ListItem className={classes.listitem}>
              <ListItemLink href="https://www.amclives.com/" className={classes.link}>
                <ListItemText primary="American Performance" />
              </ListItemLink>
            </ListItem>
            <ListItem className={classes.listitem}>
              <ListItemLink href="http://www.smsautofabrics.com" className={classes.link}>
                <ListItemText primary="SMS - Seat Fabric, Door Panels, etc.  Original Material." />
              </ListItemLink>
            </ListItem>
            <ListItem className={classes.listitem}>
              <ListItemLink href="http://justdashes.com" className={classes.link}>
                <ListItemText primary="Just Dashes" />
              </ListItemLink>
            </ListItem>
            <ListItem className={classes.listitem}>
              <ListItemLink href="http://www.autolit.com/Store/amc/" className={classes.link}>
                <ListItemText primary="Autolit.com - Original Automobile literature" />
              </ListItemLink>
            </ListItem>
            <ListItem className={classes.listitem}>
              <ListItemLink href="https://www.ramblerranch.com/" className={classes.link}>
                <ListItemText primary="Rambler Ranch - Private collection of Nash, Rambler and AMC history" />
              </ListItemLink>
            </ListItem>
          </List>
        </Paper>
        <Footer />
      </div>
    </Fragment>
  );
}

LinksPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LinksPage);
