import React, { Component, Fragment } from 'react';
import { Routes, Route, Link, BrowserRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import pgrrLogo from './assets/images/pgrr_logo.png';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import HomePage from './pages/Home';
import HomeIcon from '@material-ui/icons/Home';
import EventsPage from './pages/Events';
import EventIcon from '@material-ui/icons/Event';
import PhotosPage from './pages/Photos';
import PhotoIcon from '@material-ui/icons/PhotoCamera';
import JoinPage from './pages/Join';
import JoinIcon from '@material-ui/icons/VerifiedUser';
import BoardPage from './pages/Board';
import PeopleIcon from '@material-ui/icons/People';
import LinksPage from './pages/Links';
import LinkIcon from '@material-ui/icons/Link';
import UpcomingPage from './pages/Upcoming';
import SantamariaPage from './pages/Santamaria';
import ClassifiedsPage from './pages/Classifieds';
import NewsletterPage from './pages/Newsletter';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

const styles = ({
  tabsIndicator: {
    backgroundColor: '#EEEEEE',
  },
  tabRoot: {
    minWidth: 72,
    fontSize: '1.0rem',
    fontWeight: 300,
    display: 'block',
  },
  h1: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 300,
    fontSize: '2.0rem',
    flexGrow: 1,
  },
  logo: {
    width: 50,
    height: 50,
    marginLeft: 10,
  },
  appbarColor: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    minHeight: '72px',
  },
  tabone: {
    background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
  },
  menuButton: {
    display: 'none',
  },
  '@media (max-width: 1065px)': {
    h1: {
      fontSize: '1.6rem',
    },
    tabRoot: {
      maxWidth: 90,
      fontSize: '0.9rem',
    }
  },
  '@media (max-width: 860px)': {
    tabRoot: {
      minWidth: 50,
      fontSize: '0',
    }
  },
  '@media (max-width: 720px)': {
    tabsIndicator: {
      backgroundColor: 'transparent',
    },
    tabsRootOn: {
      position: 'absolute',
      top: 50,
      right: 20,
      backgroundColor: '#777777',
    },
    tabsRootOff: {
      display: 'none',
    },
    menuButton: {
      display: 'flex',
    },
  },
});


class App extends Component {
  state = {
    isActive: 0
  };

  handleChange = (event, value) => {
    this.setState({ isActive: 0 });
  };

  toggleClass = (event) => {
    event.preventDefault();
    this.setState({ isActive: !this.state.isActive });
  };

  render() {
    const { classes } = this.props;
    
    // for paths not associated with a tab, need to set Tabs value to false - https://material-ui.com/api/tabs/#props
    let path = window.location.pathname;
    let tabsValue = (path === '/newsletter' || path === '/upcoming' || path === '/classifieds' || path === '/santamaria') ? false : path;

    return (
      <BrowserRouter>
        <Fragment>
          <AppBar position="fixed" classes={{ root: classes.appbarColor }}>
            <Toolbar>
              <Typography component="h1" variant="h4" color="inherit" className={classes.h1}>
                Pacific Gold Rush <img src={pgrrLogo} alt="PGRR" className={classes.logo} />amblers
              </Typography>
              <Tabs 
                value={tabsValue}
                onChange={this.handleChange}
                indicatorColor="primary" 
                classes={ this.state.isActive ? { root: classes.tabsRootOn, indicator: classes.tabsIndicator } : { root: classes.tabsRootOff, indicator: classes.tabsIndicator } }
                centered >
                <Tab label="Home" component={Link} to="/" value="/" icon={<HomeIcon />} classes={{ root: classes.tabRoot }} />
                <Tab label="Events" component={Link} to="/events" value="/events" icon={<EventIcon />} classes={{ root: classes.tabRoot }} />
                <Tab label="Photos" component={Link} to="/photos" value="/photos" icon={<PhotoIcon />} classes={{ root: classes.tabRoot }} />
                <Tab label="Membership" component={Link} to="/join" value="/join" icon={<JoinIcon />} classes={{ root: classes.tabRoot }} />
                <Tab label="Board" component={Link} to="/board" value="/board" icon={<PeopleIcon />} classes={{ root: classes.tabRoot }} />
                <Tab label="Links" component={Link} to="/links" value="/links" icon={<LinkIcon />} classes={{ root: classes.tabRoot }} />
              </Tabs>
              <IconButton className={classes.menuButton} color="inherit" onClick={this.toggleClass}>
                <MenuIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Typography component="div" style={{ marginTop: 72 }}>
            <Routes>
              <Route exact path="/" element={<HomePage />} />
              <Route path="/events" element={<EventsPage />} />
              <Route path="/photos" element={<PhotosPage />} />
              <Route path="/join" element={<JoinPage />} />
              <Route path="/board" element={<BoardPage />} />
              <Route path="/links" element={<LinksPage />} />
              <Route path="/upcoming" element={<UpcomingPage />} />
              <Route path="/santamaria" element={<SantamariaPage />} />
              <Route path="/classifieds" element={<ClassifiedsPage />} />
              <Route path="/newsletter" element={<NewsletterPage />} />
            </Routes>
          </Typography>
        </Fragment>
      </BrowserRouter>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(App);
