import React from 'react';
import IMAGES from '../../assets/json/gallery.json';
import Gallery from 'react-grid-gallery';

function carGallery() {
  return (
    <div>
      <Gallery images={IMAGES} enableImageSelection={false} />
    </div>
  );
}

export default carGallery;
