import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Iframe from 'react-iframe';

const styles = {
  root: {
    width: 'auto',
    height: 'calc(100vh - 76px)',
  },
  openButton: {
    position: 'absolute',
    right: '50px',
    top: '135px',
    zIndex: '2',
    width: '200px',
    height: '44px',
  },
  openButton2: {
    position: 'absolute',
    right: '50px',
    top: '200px',
    zIndex: '2',
    width: '200px',
    height: '44px',
  }
};

function EventsPage(props) {
  const { classes } = props;
  const fetchHeader = (filename, property) => {
    try {
        let req=new XMLHttpRequest();
        req.open("HEAD", filename, false);
        req.send(null);
        if(req.status === 200){
            return req.getResponseHeader(property);
        }
        else return false;
    } catch(er) {
        return er.message;
    }
  }
  const eventCalendar = "../../PGRRAMBLER_EVENT_CALENDAR.pdf"
  const modifiedDate = fetchHeader(eventCalendar,'Last-Modified');
  const unixTime = Date.parse(modifiedDate);
  const eventCalendarUrl = isNaN(unixTime) ? eventCalendar : eventCalendar + '?cache=' + unixTime ;

  return (
    <div className={classes.root}>
      <Button className={classes.openButton} variant="contained" color="secondary" component={RouterLink} to="/upcoming">
        Club Update
      </Button>
      {/* <Button className={classes.openButton2} variant="contained" color="secondary" component={RouterLink} to="/santamaria">
        Santa Maria!
      </Button> */}
      <object
        data={eventCalendarUrl}
        type="application/pdf"
        width="100%"
        height="100%"
      >
        <Iframe url={eventCalendarUrl}
          width="100%"
          height="100%"
          id="PDFviewer"
          display="initial"
          position="relative"
        />
      </object>
    </div>
  );
}

EventsPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EventsPage);
