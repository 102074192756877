import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Gallery from '../../components/Gallery';
import dropboxIcon from '../../assets/images/dropbox.png';
import facebookIcon from '../../assets/images/facebook.png';

const styles = {
  root: {
    width: 'auto',
    padding: 8 * 3,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    marginBottom: 0,
  },
  paragraph: {
    marginTop: '-15px',
    fontSize: '1.2em',
    alignItems: 'center',
    display: 'flex',
  },
  icons: {
    margin: 10,
    width: 40,
    height: 40,
  },
};

function PhotosPage(props) {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <div id="fb-root"></div>
      <script async defer crossOrigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v6.0&appId=1002393986553971&autoLogAppEvents=1"></script>
      <div className={classes.header}>  
        <Typography component="h2" variant="h5" color="inherit" className={classes.title}>
          Member's Cars:
        </Typography>
        <Typography variant="body1" className={classes.paragraph}>
          Past club event photos also available on 
          <a href="https://www.dropbox.com/sh/56tu8nc0ug1hjhy/AAC8fNDethN88n2LO7xopkCca?dl=0" target="_blank" rel="noopener noreferrer">
            <img src={dropboxIcon} className={classes.icons} alt="Dropbox" title="Dropbox" />
          </a> 
          and 
          <a href="https://www.facebook.com/groups/PGRRC/photos/" target="_blank" rel="noopener noreferrer">
            <img src={facebookIcon} className={classes.icons} alt="Facebook" title="Facebook" />
          </a>
        </Typography>
      </div>
      <Gallery />
      <div data-href="https://www.facebook.com/groups/PGRRC/photos/" data-tabs="photos" data-width="" data-height="" data-small-header="true" data-adapt-container-width="true" data-hide-cover="true" data-show-facepile="true"></div>
   </div>
  );
}

PhotosPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PhotosPage);
